import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import BackgroundImage from "gatsby-background-image"
import classes from "./blogSectionBgImage.module.scss"

const blogSectionBgImage = (props) => {
  // const bgStyle = {
  //   background: `url(${props.bgImage}) no-repeat center center fixed`,
  //   backgroundSize: 'cover',
  //   height: '800px',
  // }
  return (
    <BackgroundImage
      className={classes.bgImage}
      fluid={props.fluid}
      preserveStackingContext={true}
    >
      <Row>
        <Col sm={6} className={classes.bgImageCentered}>
          <h1 className={classes.bgImageTitle}>{props.title}</h1>
        </Col>
      </Row>
    </BackgroundImage>


  )
}

export default blogSectionBgImage
