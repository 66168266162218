import React from "react"
import classes from "./blogNextPrev.module.scss"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"
import LocalizedLink from "../../LocalizedLink"
import { FormattedMessage } from "react-intl"
import Col from "react-bootstrap/Col"
import svgArrow from "../../../images/svg/126490.svg"

const blogNextPrev = (props) => {

  let typeClasses = {}
  if (props.type === "previous") {
    typeClasses = {
      wrapper: [classes.blogNavItem, classes.previousPost].join(" "),
      labelBtn: classes.labelPrevious,
      arrow: classes.previousArrow,
    }
  } else {
    typeClasses = {
      wrapper: [classes.blogNavItem, classes.nextPost].join(" "),
      labelBtn: classes.labelNext,
      arrow: classes.nextArrow,
    }
  }
  return (

    <Col sm={6} className="overflow-hidden">
        <BackgroundImage
          className={classes.postBgImg}
          fluid={props.fluid}

        >
          <div className={typeClasses.wrapper}>

            <LocalizedLink to={props.url}/>
            <h3>
          <span className={typeClasses.labelBtn}>
            {props.type === "previous" ? <FormattedMessage id="prevBlog"/> : <FormattedMessage id="nextBlog"/>}
          </span>
              <span className={classes.text}>
            {props.title}
                <img alt="svgArrow" className={typeClasses.arrow} src={svgArrow}/>
            <span className={classes.line}/>
          </span>
            </h3>
          </div>
        </BackgroundImage>

    </Col>

  )
}


blogNextPrev.propTypes = {
  type: PropTypes.oneOf(["previous", "next"]).isRequired,
}

export default blogNextPrev
