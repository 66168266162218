import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import Layout from "../components/Layout/layout"
import BlogSectionBgImage from "../components/Layout/BlogSectionBgImage/blogSectionBgImage"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import BoxedItem from "../components/Misc/BoxedItem/boxedItem"
import BlogCallToAction from "../components/Blog/BlogCallToAction/blogCallToAction"
import BlogNextPrev from "../components/Blog/BlogNextPrev/blogNextPrev"

// import SEO from "../components/seo"

class SingleBlog extends React.Component {
  render() {

    const post = this.props.data.wordpressPost
    const next = this.props.data.next
    const prev = this.props.data.prev
    return (
      <Layout locale={this.props.pageContext.locale} path={this.props.path}>
        <SEO
          title={post.title}
          lang={this.props.pageContext.locale}
          description={post.yoast_meta.yoast_wpseo_metadesc ? post.yoast_meta.yoast_wpseo_metadesc : null}
          image={post.yoast_meta.yoast_wpseo_facebook_image ? post.yoast_meta.yoast_wpseo_facebook_image : post.featured_media.localFile.childImageSharp.fixed.src}
        />
        <BlogSectionBgImage title={post.title} fluid={post.featured_media.localFile.childImageSharp.fluid}/>
        <Container>
          <Row>
            <Col sm={12}>
              <BoxedItem>
                <div className="postContent" style={{ padding: "3%", marginTop: "30px", marginBottom: "30px" }}>
                  {parse(post.content)}
                  {post.acf.call_to_action_heading && post.acf.call_to_action_link && post.acf.call_to_action_button_text ?

                    <BlogCallToAction
                      heading={post.acf.call_to_action_heading}
                      url={post.acf.call_to_action_link}
                      btnText={post.acf.call_to_action_button_text}/> : null

                  }
                </div>
              </BoxedItem>
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <Row noGutters={true}>
            { // Prev blog Item
              prev && prev.featured_media ? <BlogNextPrev
                type="previous"
                url={"/blog/" + prev.slug}
                fluid={prev.featured_media.localFile.childImageSharp.fluid}
                title={prev.title}
              /> : <Col sm={6}/>
            }

            { // Next blog Item
              next && next.featured_media ? <BlogNextPrev
                type="next"
                url={"/blog/" + next.slug}
                fluid={next.featured_media.localFile.childImageSharp.fluid}
                title={next.title}
              /> : null
            }
          </Row>
        </Container>

        {/*<div></div>*/}
      </Layout>
    )
  }
}

SingleBlog.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default SingleBlog

export const pageQuery = graphql`
    query($slug: String!,$next: String, $prev: String) {
        wordpressPost(slug: {eq:$slug}) {
            acf {
                call_to_action_heading
                call_to_action_link
                call_to_action_button_text
            }
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth:2100) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                        fixed(width: 1200){
                            src
                        }
                    }
                }
                slug
            }
            slug
            path
            title
            content
            yoast_meta {
                yoast_wpseo_facebook_image
                yoast_wpseo_facebook_description
                yoast_wpseo_facebook_title
                yoast_wpseo_facebook_type
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
        }
        next: wordpressPost(slug: {eq:$next}) {
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth:1200) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
            slug
            path
            title
        }
        prev: wordpressPost(slug: {eq:$prev}) {
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth:1200) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
            slug
            path
            title
        }
    }

`
