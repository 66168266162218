import React from "react"
import classes from "./blogCallToAction.module.scss"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import GradientButton from '../../Misc/GradientButton/gradientButton'
const blogCallToAction = (props) => {
  return (
    <div className={classes.Wrapper}>
      <Row>
        <Col sm={2}/>
        <Col sm={8}>
          <h2 className={classes.CallToActionHeading}>{props.heading}</h2>
          <GradientButton btnText={props.btnText} icon="long-arrow-alt-right" url={props.url}/>
        </Col>
        <Col sm={2}/>
      </Row>
    </div>
  )
}

export default blogCallToAction
